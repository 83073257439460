import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
//import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import {Platform} from '@ionic/angular';
import {filter} from 'rxjs/operators';
import {EnvService} from '../environment/env.service';

declare const gtag: Function;
declare let ga: Function;

@Injectable({
	providedIn: 'root'
})
export class GaService {
	constructor(
		//private ga: GoogleAnalytics,
		private envService: EnvService,
		public platform: Platform,
		private router: Router
	) { }

	addGAScript() {
		let gtagScript: HTMLScriptElement = document.createElement('script');
		gtagScript.async = true;
		gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${this.envService.GA_TRACKING_ID}`;
		document.head.prepend(gtagScript);
    gtag('js', new Date());
		gtag('config', this.envService.GA_TRACKING_ID, {
			send_page_view: false
		});
	}

	addGAKey() {
		const gaScript1 = document.createElement('script');
		gaScript1.innerText = `window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;ga(\'create\', \'${this
			.envService.GA_TRACKING_ID}\', \'auto\');`;

		const gaScript = document.createElement('script');
		gaScript.setAttribute('async', 'true');
		gaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=' + this
      .envService.GA_TRACKING_ID);
    
		document.documentElement.firstChild.appendChild(gaScript1);
		document.documentElement.firstChild.appendChild(gaScript);
	}

	public isMobile() {
		return (this.platform.platforms().includes("mobile") && !this.platform.platforms().includes("mobileweb")) || ((this.platform.platforms().includes("android") ||
			this.platform.platforms().includes("ios")) && !this.platform.platforms().includes("mobileweb"));
	}

	initGa(): Promise<any> {

		return new Promise((resolve, reject) => {
			console.log('ga test mobile ', this.platform.platforms().includes('mobile'));
			console.log('ga test mobileweb ', this.platform.platforms().includes('mobileweb'));
			console.log('ga test desktop ', this.platform.platforms().includes('desktop'));
			if (this.isMobile()) {
				console.log('google analytics: is Mobile, disabled')
				/*this.ga.startTrackerWithId(this.envService.GA_TRACKING_ID).then(() => {
					console.log('Google analytics is ready now');
					this.ga.setAppVersion('mobile');

					resolve(this.initRoutingEvent('mobile'));
				  }).catch((e) => {
					console.log('Error starting GoogleAnalytics', e)
					reject(e);
				  });*/
			} else if (this.platform.platforms().includes('desktop') || this.platform.platforms().includes('mobileweb')) {
				console.log('google analytics: is not Mobile, enabling through script')
				this.addGAScript();
				this.addGAKey();

				resolve(this.initRoutingEvent('desktop'));
			}
		})
	}

	initRoutingEvent(platform: any) {
		// if(platform == 'desktop') {

		// 	console.log('Init router web');

		// 	this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
		// 		gtag('event', 'page_view', {
		// 			page_path: event.urlAfterRedirects
		// 		});
		// 		ga('send', 'pageview');

		// 		console.log('GA router event', event);
		// 	});
		// }
		// else if(platform == 'mobile') {

		// 	console.log('Init router mobile');

		// 	this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
		// 		this.ga.trackView('pageViewMobile', event.urlAfterRedirects);
		// 		console.log('GA router event', event);
		// 	});
		// }
	}
}
